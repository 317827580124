<article class="d-flex mt-5 px-4 pb-5 px-md-0">
  <div class="containerDashboard">

    <section class="card-shadow px-4 pb-3">
      <div class="py-4 border-bottom">
        <h3 class="font-weight-bold font-size-32 color-blue-first m-0">{{ 'PAGE.PLAN_DASHBOARD' | translate }}</h3>
      </div>

      <div class="mt-4 table-responsive">
        <!-- Table -->
        <table class="table table-striped" id="tableX">
          <thead>
            <tr scope="col">
              <th class="py-3 border-0">Token</th>
              <th class="py-3 border-0">{{productsData[0]?.name}}</th>
              <th class="py-3 border-0">{{productsData[1]?.name}}</th>
              <th class="py-3 border-0">{{productsData[2]?.name}}</th>
              <th class="py-3 border-0">{{productsData[3]?.name}}</th>
            </tr>
          </thead>

          <tbody>
            <tr scope="row" class="py-1" *ngFor="let token of tokens">
              <th>
                {{token.symbol}}
                <span *ngIf="token.status == 'awaitingPayment'" class="badge badge-danger text-white ml-2"><i class="fa fa-exclamation-triangle" placement="auto" ngbTooltip="{{ 'PAGE.TOKEN_AWAITING_PAYMENT_MESSAGE' | translate }}"></i>
                </span>
                <span *ngIf="token.status == 'paid'" class="badge badge-warning text-white ml-2"><i class="fa fa-exclamation-triangle" placement="auto" ngbTooltip="{{ 'PAGE.TOKEN_NOT-DEPLOYED_MESSAGE' | translate }}"></i>
                </span>
              </th>
              <td>
                <span *ngIf="token.product.includes(1)" class="badge badge-success text-white mr-4"><i class="fa fa-check" placement="auto" ngbTooltip="{{productsData[0].description}}"></i>
                </span>
                <span *ngIf="!token.product.includes(1)" class="badge badge-danger text-white mr-4"><i class="fa fa-times" placement="auto" ngbTooltip="{{productsData[0].description}}"></i>
                </span>
              </td>
              <td>
                <span *ngIf="token.product.includes(2)" class="badge badge-success text-white mr-4"><i class="fa fa-check" placement="auto" ngbTooltip="{{productsData[1].description}}"></i>
                </span>
                <span *ngIf="!token.product.includes(2)" class="badge badge-danger text-white mr-4"><i class="fa fa-times" placement="auto" ngbTooltip="{{productsData[1].description}}"></i>
                </span>
              </td>
              <td>
                <span *ngIf="token.product.includes(3)" class="badge badge-success text-white mr-4"><i class="fa fa-check" placement="auto" ngbTooltip="{{productsData[2].description}}"></i>
                </span>
                <span *ngIf="!token.product.includes(3)" class="badge badge-danger text-white mr-4">
                  <i class="fa fa-times" placement="auto" ngbTooltip="{{productsData[2].description}}"></i>
                </span>
              </td>
              <td>
                <span *ngIf="token.product.includes(4)" class="badge badge-success text-white mr-4"><i class="fa fa-check" placement="auto" ngbTooltip="{{productsData[3].description}}"></i>
                </span>
                <span *ngIf="!token.product.includes(4)" class="badge badge-danger text-white mr-4"><i class="fa fa-times" placement="auto" ngbTooltip="{{productsData[3].description}}"></i>
                </span>
              </td>
              <td>
                <a *ngIf="token.status == 'awaitingPayment'" class="btn btn-sm text-white btn-secondary float-right" (click)="selectTokenId(token._id)" [routerLink]="['/pages/choose-plan']">{{ 'PAGE.PLAN_ACTIVATE' | translate }}</a>
                <a *ngIf="token.status !== 'awaitingPayment'" class="btn btn-sm text-white btn-hero float-right" (click)="selectTokenId(token._id)" [routerLink]="['/pages/edit-plan']">{{ 'PAGE.PLAN_EDIT' | translate }}</a>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- FIM Table  -->
      </div>

      <button [routerLink]="['/pages/create-token-desc']" class="btn-form bg-blue-first font-weight-medium color-white-primary mb-3">+ {{ 'PAGE.CREATE_TOKEN_DASHBOARD' | translate }}</button>
    </section>
  </div>
</article>