import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PagesAppComponent } from './pages.app.component';
import { CreateTokenComponent } from './create-token/create-token.component';
import { CreateTokenDescriptionComponent } from './create-token-description/create-token-description.component';
import { CreateTokenInterfaceComponent } from './create-token-interface/create-token-interface.component';
import { CreateTokenResumeComponent } from './create-token-resume/create-token-resume.component';
import { CreateTokenRegisterComponent } from './create-token-register/create-token-register.component';
import { ChoosePlanComponent } from './choose-plan/choose-plan.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { AuthenticationGuard } from '../route-guards/authentication.guard';
import { MintToComponent } from './mint-to/mint-to.component';
import { BurnFromComponent } from './burn-from/burn-from.component';
import { DashboardTokenComponent } from './dashboard-token/dashboard-token.component';
import { DashboardPlanComponent } from './dashboard-plan/dashboard-plan.component';
import { DashboardAccountComponent } from './dashboard-account/dashboard-account.component';
import { EditPlanComponent } from './edit-plan/edit-plan.component';

const routerConfig: Routes = [
    {
        path: '', component: PagesAppComponent,
        children: [
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard] },
            { path: 'dashboard-token', component: DashboardTokenComponent, canActivate: [AuthenticationGuard] },
            { path: 'dashboard-plan', component: DashboardPlanComponent, canActivate: [AuthenticationGuard] },
            { path: 'dashboard-account', component: DashboardAccountComponent, canActivate: [AuthenticationGuard] },
            { path: 'create-token', component: CreateTokenComponent },
            { path: 'create-token-desc', component: CreateTokenDescriptionComponent },
            { path: 'choose-plan', component: ChoosePlanComponent, canActivate: [AuthenticationGuard] },
            { path: 'create-token-inter', component: CreateTokenInterfaceComponent },
            { path: 'create-token-resume', component: CreateTokenResumeComponent},
            { path: 'create-token-register', component: CreateTokenRegisterComponent},
            { path: 'login', component: LoginComponent },
            { path: 'payment/:id/:address/:desc/:value', component: PaymentComponent, canActivate: [AuthenticationGuard] },
            { path: 'payment-confirmation/:status', component: PaymentConfirmationComponent, canActivate: [AuthenticationGuard] },
            { path: 'mint-to', component: MintToComponent, canActivate: [AuthenticationGuard] },
            { path: 'burn-from', component: BurnFromComponent, canActivate: [AuthenticationGuard] },
            { path: 'edit-plan', component: EditPlanComponent, canActivate: [AuthenticationGuard] }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routerConfig)
    ],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
