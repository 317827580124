<article class="d-flex mt-5 px-4 pb-5 px-md-0">
  <div class="containerDashboard">

    <section class="card-shadow px-4 pb-3">
      <div class="py-4 border-bottom">
        <h3 class="font-weight-bold font-size-32 color-blue-first m-0">{{ 'PAGE.ACCOUNT_DASHBOARD' | translate }}</h3>
      </div>

      <form class="my-4">
        <label class="mb-1 font-archivo font-size-18 font-weight-medium" for="email">E-mail</label>
        <input disabled type="text" class="form-control" id="email" name="email" value="{{userId}}" />

        <button disabled placement="auto" ngbTooltip="{{ 'PAGE.CANCEL_ACCOUNT_TOOLIP' | translate }}" class="btn-form bg-red-primary mt-3 color-white-primary">{{ 'PAGE.CANCEL_ACCOUNT_BUTTON' | translate }}</button>
      </form>
    </section>

    <section class="card-shadow mt-4 px-4 pb-3">
      <div class="py-4 border-bottom">
        <h3 class="font-weight-bold font-size-32 color-blue-first m-0">Tokens</h3>
      </div>

      <ul class="mt-4 mb-0 w-100 p-0 d-flex flex-wrap">
        <li *ngFor="let token of tokens" class="border col-12 col-sm-3 d-flex flex-column align-items-center mr-sm-4 mb-4 ng-star-inserted p-4 rounded">

          <span *ngIf="token.status == 'deployed'" class="badge badge-success text-white mb-2 p-2"><i class="fa fa-check" placement="auto" ngbTooltip="Address: {{token.address}}"></i>
          </span>
          <span *ngIf="token.status == 'paid'" class="badge badge-warning text-white mb-2 p-2"><i class="fa fa-exclamation-triangle" placement="auto" ngbTooltip="{{ 'PAGE.TOKEN_NOT-DEPLOYED_MESSAGE' | translate }}"></i>
          </span>
          <span *ngIf="token.status == 'awaitingPayment'" class="badge badge-danger text-white mb-2 p-2"><i class="fa fa-exclamation-triangle" placement="auto" ngbTooltip="{{ 'PAGE.TOKEN_AWAITING_PAYMENT_MESSAGE' | translate }}"></i>
          </span>

          <h3 class="color-blue-third font-size-18 font-weight-semibold">{{token.symbol}}</h3>

          <p class="m-0"><span class="font-weight-medium">{{ 'PAGE.NAME' | translate }}</span> {{token.name}}</p>
          <p class="m-0"><span class="font-weight-medium">{{ 'PAGE.TYPE' | translate }}</span> {{token.type}}</p>
          <p class="m-0"><span class="font-weight-medium">{{ 'PAGE.PLAN_DASHBOARD' | translate }}</span></p>
          <span *ngFor="let license of licenses" class="badge badge-info mr-3 p-2">
            <div *ngIf="license.tokenId == token._id">{{license.productId}}</div>
          </span>
        </li>
      </ul>
    </section>

    <section class="card-shadow mt-4 px-4 pb-3">
      <div class="py-4 border-bottom">
        <h3 class="font-weight-bold font-size-32 color-blue-first m-0">Payments</h3>
      </div>

      <div class="mt-4 table-responsive">
        <!-- Table -->
        <table class="table table-striped" id="tableX">
          <thead>
            <tr scope="col">
              <th class="py-3 border-0">Token Id</th>
              <th class="py-3 border-0">{{ 'PAGE.ACCOUNT_DATE' | translate }}</th>
              <th class="py-3 border-0">{{ 'PAGE.ACCOUNT_VALUE' | translate }}</th>
            </tr>
          </thead>

          <tbody>
            <tr scope="row" class="py-1" *ngFor="let payment of payments">
              <td class="py-3">{{payment.tokenId}}</td>
              <td class="py-3">{{payment.dateOfPayment}}</td>
              <td class="py-3">R$ {{payment.paymentValue}},00</td>
            </tr>
          </tbody>
        </table>
        <!-- FIM Table  -->
      </div>

      <button [routerLink]="['/pages/create-token-desc']" class="btn-form bg-blue-first font-weight-medium color-white-primary mb-3">+ {{ 'PAGE.CREATE_TOKEN_DASHBOARD' | translate }}</button>
    </section>

  </div>
</article>