import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { TokenService } from 'src/app/services/token.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MintRequestDto } from 'src/app/dtos/mint-request.dto';
import { DataService } from 'src/app/utils/data.service';
import { UserService } from 'src/app/services/user.service';
import { GetUserDto } from 'src/app/dtos/get-user.dto';
import { GetTokenDto } from 'src/app/dtos/get-token.dto';
import { Router } from '@angular/router';
import { Product } from '../../models/product';
import { RegisterPaymentDto } from 'src/app/dtos/register-payment.dto';
import { ProductService } from 'src/app/services/product.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SetupService } from 'src/app/services/setup.service';
import { LicenseService } from 'src/app/services/license.service';
import { LicenseModel } from 'src/app/models/license.model';
import { SetupModel } from 'src/app/models/setup.model';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.css']
})
export class EditPlanComponent implements OnInit {

  user: GetUserDto;
  userId: string;
  form: FormGroup;
  request: MintRequestDto;
  token: GetTokenDto;
  tokenId: string;
  productsData: Product[];
  preSelectedProductsIds: number[];
  selectedProductsIds: number[];
  licenseData: LicenseModel[];
  setupData: SetupModel[];
  totalSetupPrice = 0;
  totalMonthlyPrice = 0;
  payment = new RegisterPaymentDto('', '', 0, ' ', []);

  get productsFormArray() {
    return this.form.controls.products as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private tokenService: TokenService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dataService: DataService,
    private router: Router,
    private productService: ProductService,
    private paymentService: PaymentService,
    private setupService: SetupService,
    private licenseService: LicenseService,
    private formBuilder: FormBuilder
  ) {

    this.form = this.formBuilder.group({
      products: new FormArray([])
    });

  }

  ngOnInit(): void {

    this.spinner.show();
    this.userId = this.userService.getUserLocalStorage();
    this.dataService.currentTokenId.subscribe(tokenId => this.tokenId = tokenId);

    this.userService
      .getByEmail(this.userId)
      .subscribe((response: GetUserDto) => {
        this.user = response
      });

    this.tokenService
      .getById(this.tokenId)
      .subscribe((response: GetTokenDto) => {
        this.token = response[0]
        this.preSelectedProductsIds = response[0].product
      });

    //this.setProducts - > this.selectedProductsIds // select products on start

    this.licenseService
      .getLicenseByToken(this.tokenId)
      .subscribe((response: LicenseModel[]) => {
        this.licenseData = response
      });

    this.setupService
      .getSetupByToken(this.tokenId)
      .subscribe((response: SetupModel[]) => {
        this.setupData = response
      });

    this.listProducts();
    this.spinner.hide();

  }

  private addCheckboxes() {
    this.productsData.forEach(() => this.productsFormArray.push(new FormControl(false)));
  }

  async listProducts() {
    this.productService.getProduct()
      .subscribe((response: Product[]) => {
        this.productsData = response;
        this.addCheckboxes();
      });
  }

  FieldsChange(values: any, priceSetup: number, priceMonthly: number) {
    if (values.currentTarget.checked) {
      this.totalSetupPrice = +this.totalSetupPrice + +priceSetup;
      this.totalMonthlyPrice = +this.totalMonthlyPrice + +priceMonthly;
    } else {
      this.totalSetupPrice = this.totalSetupPrice - priceSetup;
      this.totalMonthlyPrice = this.totalMonthlyPrice - priceMonthly;
    }
  }

  submitProducts() {
    const selectedProductsIds = this.form.value.products
      .map((checked, i) => checked ? this.productsData[i].id : null)
      .filter(v => v !== null);
    return selectedProductsIds;
  }

  getDate() {
    let date_ob = new Date();
    let day = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    return year + "-" + month + "-" + day;
  }

  async updatePlan() {

    this.spinner.show();
    this.selectedProductsIds = this.submitProducts();
    this.selectTokenId(this.tokenId);
    const date = this.getDate();
    const totalPaymentPrice = this.totalSetupPrice + this.totalMonthlyPrice;
    this.tokenService.saveOldProductsLocalStorage(this.token.product);
    this.paymentService.savePaymentLocalStorage(new RegisterPaymentDto(this.userId, this.tokenId, totalPaymentPrice, date, this.selectedProductsIds));
    this.router.navigate(['pages/payment', this.tokenId, this.userId, "CreateToken", totalPaymentPrice]);
    this.spinner.hide();

  }

  async cancelLicense(ProductId: number) {

    if (window.confirm('Confirm Delete License?')) {
      this.spinner.show();
      this.preSelectedProductsIds.forEach((item, index) => {
        if (item === ProductId) this.preSelectedProductsIds.splice(index, 1);
      });
      this.tokenService.updateProduct(this.tokenId, this.preSelectedProductsIds).subscribe((response) => { });
      const tokenId = this.tokenId;
      this.licenseService.deleteLicense(tokenId, ProductId)
        .subscribe(
          success => { this.runDeactivateLicenseSuccess() },
          error => { this.runDeactivateLicenseError(error) }
        );
    }
  }

  runDeactivateLicenseSuccess() {
    const toastr = this.toastrService.success(`Licence canceled`, 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.router.navigate(['pages/dashboard-plan']);
        this.spinner.hide();
      });
  }

  runDeactivateLicenseError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngOnInit();
        this.spinner.hide();
      });
  }

  selectTokenId(newId: string) {
    this.dataService.changeTokenId(newId)
  }
}
