<section class="bg-Interface">
  <div class="containerX p-3">
    <div class="mb-5">
      <h1 class="
          text-center
          font-archivo font-size-44
          color-blue-third
          font-weight-semibold
        ">
        Create token / Interface
      </h1>
      <p class="text-center pb-5">
        {{ 'PAGE.FUNCTIONS_DESCRIPTION' | translate }}
      </p>
    </div>
    <!-- INICIO -->
    <div class="mb-5">
      <h4 class="page-title">{{ 'PAGE.FUNCTIONS' | translate }}</h4>
      <ul class="d-block d-lg-flex p-0">
        <li class="
            card-interface
            d-flex
            list-group-item
            enable
            mr-2
            border-0
            card-shadow
            my-2 my-lg-0
          ">
          <div class="mr-3">
            <img src="../../../assets/img-bft/icon-award.svg" alt="" />
          </div>
          Balance of
        </li>
        <li class="
            card-interface
            d-flex
            list-group-item
            enable
            mr-2
            border-0
            card-shadow
            my-2 my-lg-0
          ">
          <div class="mr-3">
            <img src="../../../assets/img-bft/icon-award.svg" alt="" />
          </div>
          Transfer
        </li>
        <li class="
            card-interface
            d-flex
            list-group-item
            enable
            mr-2
            border-0
            card-shadow
            my-2 my-lg-0
          ">
          <div class="mr-3">
            <img src="../../../assets/img-bft/icon-award.svg" alt="" />
          </div>
          Transfer from
        </li>
        <li class="
            card-interface
            d-flex
            list-group-item
            enable
            border-0
            card-shadow
            my-2 my-lg-0
          ">
          <div class="mr-3">
            <img src="../../../assets/img-bft/icon-award.svg" alt="" />
          </div>
          Allowance
        </li>
      </ul>
    </div>
    <div class="mb-5">
      <h4 class="page-title">Events</h4>
      <ul class="d-block d-lg-flex p-0">
        <li class="
            card-interface
            d-flex
            list-group-item
            enable
            mr-2
            border-0
            card-shadow
            my-2 my-lg-0
          ">
          <div class="mr-3">
            <img src="../../../assets/img-bft/icon-chevrons-right.svg" alt="" />
          </div>
          Balance of
        </li>
        <li class="
            card-interface
            d-flex
            list-group-item
            enable
            border-0
            card-shadow
            my-2 my-lg-0
          ">
          <div class="mr-3">
            <img src="../../../assets/img-bft/icon-chevrons-right.svg" alt="" />
          </div>
          Transfer
        </li>
      </ul>
    </div>

    <!-- FIM -->
    <div class="mb-5 pb-sm-5">
      <div class="d-block d-sm-flex p-0">
        <button class="btn btn-secondX w-100 col-sm-6 mr-2 my-2 my-lg-0" [routerLink]="['/pages/create-token-desc']">
          {{ 'PAGE.BACK' | translate }}
        </button>

        <button class="btn btn-primaryX w-100 col-sm-6 my-2 my-lg-0" [routerLink]="['/pages/create-token-resume']">
          {{ 'PAGE.NEXT' | translate }}
        </button>
      </div>
    </div>
    <!-- FIM -->
  </div>
</section>
<footer class="text-center py-4">
  <p class="m-0 color-blue-third font-italic font-weight-medium font-size-14">
    © BFT Solutions - Todos os direitos reservados
  </p>
</footer>