import { Injectable } from '@angular/core';
import { CreateTokenModel } from '../models/create-token-model';
import { LocalStorageKeys } from '../utils/localstorage-util';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { MintRequestDto } from '../dtos/mint-request.dto';
import { Observable } from 'rxjs';
import { MintResponseDto } from '../dtos/mint-response.dto';
import { BurnRequestDto } from '../dtos/burn-request.dto';
import { BurnResponseDto } from '../dtos/burn-response.dto';
import { RegisterTokenDto } from '../dtos/register-token-dto';
import { GetTokenDto } from '../dtos/get-token.dto';
import { DeployResponseDto } from '../dtos/deploy-response.dto';

@Injectable()
export class TokenService extends BaseService {

    constructor(
        private readonly httpClient: HttpClient
    ) {
        super();
    }

    public saveLocalStorage(model: CreateTokenModel) {
        this.LocalStorage.set(LocalStorageKeys.token, model);;
    }

    public getLocalStorage(): CreateTokenModel {
        return this.LocalStorage.get(LocalStorageKeys.token);
    }

    public isNotSaved(): boolean {
        return !this.getLocalStorage();
    }

    public removeTokenLocalStorage() {
        this.LocalStorage.remove(LocalStorageKeys.token);
    }

    public saveOldProductsLocalStorage(products: number[]) {
      this.LocalStorage.set(LocalStorageKeys.oldProducts, products);
    }

    public getOldProductsLocalStorage(): number[] {
      return this.LocalStorage.get(LocalStorageKeys.oldProducts);
    }

    public removeOldProductsLocalStorage() {
      this.LocalStorage.remove(LocalStorageKeys.oldProducts);
    }

    register(dto: RegisterTokenDto): Observable<RegisterTokenDto> {
      const response = this.httpClient
            .post(`${this.url}/token/create`, dto, this.getHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError));
        return response;
    }

    getByUser(userId: string): Observable<GetTokenDto[]> {
        const response = this.httpClient
            .get(`${this.url}/token/user/${userId}`, this.getAuthenticationHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
        return response;
    }

    getLastByUser(userId: string): Observable<GetTokenDto> {
        const response = this.httpClient
            .post(`${this.url}/token/user/last`, { userId }, this.getAuthenticationHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
        return response;
    }

    getById(tokenId: string): Observable<GetTokenDto> {
      const response = this.httpClient
          .get(`${this.url}/token/id/${tokenId}`, this.getAuthenticationHeaderJson())
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
      return response;
  }

    getByAddress(tokenAddress: string): Observable<GetTokenDto> {
        const response = this.httpClient
            .get(`${this.url}/token/address/${tokenAddress}`, this.getAuthenticationHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
        return response;
    }

    getBySymbol(symbol: string): Observable<GetTokenDto> {
      const response = this.httpClient
          .get(`${this.url}/token/symbol/${symbol}`, this.getAuthenticationHeaderJson())
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
      return response;
  }

    updateProduct(_id: string, product: number[]): Observable<any> {
        const response = this.httpClient
            .post(`${this.url}/token/update-product`, { _id, product }, this.getAuthenticationHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
        return response;
    }

    updateTokenPayment(_id: string): Observable<any>  {
        const response = this.httpClient
            .post(`${this.url}/token/update-token-payment`, { _id }, this.getAuthenticationHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
        return response;
    }

    cancelToken(_id:string) {
      const response = this.httpClient
          .post(`${this.url}/token/delete`, { _id }, this.getAuthenticationHeaderJson())
          .pipe(
              map(this.extractData),
              catchError(this.serviceError)
          );
      return response;
  }

    public deploy(userId: string, tokenId: string): Observable<DeployResponseDto> {
        const response = this.httpClient
            .post(`${this.url}/token/deploy`, { userId, tokenId }, this.getAuthenticationHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError));
        return response;
    }

    mint(dto: MintRequestDto): Observable<MintResponseDto> {
        return this.httpClient
            .post(`${this.url}/token/mint`, dto, this.getAuthenticationHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError));
    }

    burn(dto: BurnRequestDto): Observable<BurnResponseDto> {
        return this.httpClient
            .post(`${this.url}/token/burn`, dto, this.getAuthenticationHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError));
    }
}
