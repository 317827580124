import { Injectable } from '@angular/core';
import { ethers } from 'ethers';

@Injectable()
export class WalletService {
    create() {
        return ethers.Wallet.createRandom();
    }

    async encrypt(wallet: any, password: string): Promise<any> {
        return wallet.encrypt(password);
    }
}