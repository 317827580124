<article class="bg-blue-first" id="sidebar-wrapper" style="height: 100vh;">
  <div class="sidebar-heading mt-2">
    <a class="d-flex align-items-center text-decoration-none">
      <img class="col-6" src="./assets/img/logo-castle.svg" alt="">
      <span class="text-warning d-block">
        Token Factory
      </span>
    </a>
  </div>

  <section class="list-group mt-3">
    <button [routerLink]="['/pages/dashboard']" routerLinkActive="active" class="font-weight-medium text-white border-0 bg-transparent  d-flex align-items-center list-group-item list-group-item-action">
      <i class="mr-4 fa fa-columns"></i> Dashboard
    </button>

    <button [routerLink]="['/pages/dashboard-token']" routerLinkActive="active" class="font-weight-medium text-white border-0 bg-transparent  d-flex align-items-center list-group-item list-group-item-action">
      <i class="mr-4 fa fa-cubes"></i> Tokens
    </button>

    <button [routerLink]="['/pages/dashboard-plan']" routerLinkActive="active" class="font-weight-medium text-white border-0 bg-transparent  d-flex align-items-center list-group-item list-group-item-action">
      <i class="mr-4 fa fa-file"></i> Plans
    </button>

    <button [routerLink]="['/pages/dashboard-account']" routerLinkActive="active" class="font-weight-medium text-white border-0 bg-transparent  d-flex align-items-center list-group-item list-group-item-action">
      <i class="mr-4 fa fa-user"></i> Account
    </button>
  </section>
</article>