export class RegisterTokenDto {
    constructor(
        public userId: string,
        public name: string,
        public symbol: string,
        public type: string,
        public decimalPlaces: number,
        public totalSupply: number,
        public status: string
    ) { }
}