<!--               NOVO MENU                  -->
<nav class=" navbar navbar-expand-lg navbar-light py-3">
  <div class="container-md py-1">
  <a class="navbar-brand" [routerLink]="['/pages/create-token']">
    <img src="../../../assets/img/logo-primary.svg" style="height:40px ;"/>
  </a>
  <button
    class="navbar-toggler ml-auto"
    type="button"
    data-toggle="collapse"
    data-target="#conteudoNavbarSuportado"
    aria-controls="conteudoNavbarSuportado"
    aria-expanded="false"
    aria-label="Alterna navegação"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="conteudoNavbarSuportado">
    <div class="form-inline my-2 my-lg-0 justify-content-center ml-auto">
      <select
        class="form-control mr-0 mr-sm-4 my-4 my-lg-0"
        #langSelect
        (change)="translate.use(langSelect.value)"
      >
        <option
          *ngFor="let lang of translate.getLangs()"
          [value]="lang"
          [selected]="lang === translate.currentLang"
        >
          {{ lang }}
        </option>
      </select>
      <a
        class="
          nav-link
          btn btn-secondX
          d-flex
          btn-login
          mx-auto mx-sm-0
          justify-content-center
        "
        [routerLink]="['/pages/login']"
        >
        Conecte-se
      </a>
    </div>
  </div>
  </div>
</nav>
<hr class="bg-light bg-opacity-25 m-0" />
