<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white">Loading</p>
</ngx-spinner>

<div *ngIf="!isLogged()" style="border-bottom: 1px solid var(--color-blue-sixth)">
  <app-nav></app-nav>
  <div>
    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="isLogged()" class="d-flex bg-blue-first" id="wrapper" [ngClass]="{toggled: isToogled}">
  <app-sidebar></app-sidebar>

  <div id="page-content-wrapper">
    <app-nav-logged class="w-100"></app-nav-logged>
    <div class="d-flex">
      <section class="containerDashboard">
        <router-outlet></router-outlet>
      </section>
    </div>
  </div>
</div>