import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { SetupModel } from '../models/setup.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
//import { RegisterSetupDto } from '../dtos/register-setup.dto';

@Injectable()

export class SetupService extends BaseService {
  constructor(
    private readonly httpClient: HttpClient
  ) {
    super();
  }

  getSetupByUser(userId: string): Observable<SetupModel[]> {
    return this.httpClient
      .get(`${this.url}/setup/user/${userId}`, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError));
  }

  getSetupByToken(tokenId: string): Observable<SetupModel[]> {
    return this.httpClient
      .get(`${this.url}/setup/token/${tokenId}`, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError));
  }

  registerSetup(tokenId: string, userId: string, dateSetup: string, selectedProductsIds: number[]): Observable<any> {
    return this.httpClient
      .post(`${this.url}/setup/create`, { tokenId, userId, dateSetup, selectedProductsIds }, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError));
  }

  updateSetupStatus(tokenId: string) {
    const response = this.httpClient
      .post(`${this.url}/setup/update-status-active`, { tokenId }, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
    return response;
  }

}
