import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class DataService {

    private tokenSourceAddress = new BehaviorSubject<string>("0x00");
    currentTokenAddress = this.tokenSourceAddress.asObservable();

    private tokenSourceId = new BehaviorSubject<string>(undefined);
    currentTokenId = this.tokenSourceId.asObservable();

    constructor() { }

    changeTokenAddress(tokenAddress: string){
        this.tokenSourceAddress.next(tokenAddress)
    }

    changeTokenId(tokenId: string){
        this.tokenSourceId.next(tokenId)
    }
}
