import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { RegisterNewUserModel } from 'src/app/models/register-new-user';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { RegisterUserDto } from 'src/app/dtos/register-user-dto';
import { CreateTokenModel, TokenStatus } from 'src/app/models/create-token-model';
import { TokenService } from 'src/app/services/token.service';
import { ToastrService } from 'ngx-toastr';
import { RegisterTokenDto } from 'src/app/dtos/register-token-dto';
import { DataService } from 'src/app/utils/data.service';
import { GetTokenDto } from 'src/app/dtos/get-token.dto';

@Component({
  selector: 'app-create-token-register',
  templateUrl: './create-token-register.component.html',
  styleUrls: ['./create-token-register.component.css']
})
export class CreateTokenRegisterComponent implements OnInit {

  form: FormGroup;
  model: RegisterNewUserModel;
  token: CreateTokenModel = new CreateTokenModel(
    '','', '', '', 0, 0, TokenStatus.awaitingPayment
  );
  logged: boolean;
  userId: string;
  tokenId: string;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private userService: UserService,
    private tokenService: TokenService,
    private dataService: DataService,
    private toastr: ToastrService
  ) {

    const password = new FormControl('', [Validators.required, CustomValidators.rangeLength([6, 15])]);
    const confirmPassword = new FormControl('', [Validators.required, , CustomValidators.rangeLength([6, 15]), CustomValidators.equalTo(password)]);

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: password,
      confirmPassword: confirmPassword
    });
  }

  ngOnInit(): void {
    this.spinner.show();

    if (this.tokenService.isNotSaved()) {
      const toastr = this.toastr.error('Error', 'Token is not defined!', {
        progressBar: true
      });

      if (toastr) {
        toastr.onHidden.subscribe(() => {
          this.spinner.hide();
          this.router.navigate(['/pages/create-token-desc']);
        });
      }
    } else {
      this.token = this.tokenService.getLocalStorage();;
      this.spinner.hide();
    }
  }

  async register() {
    this.spinner.show();

    this.model = Object.assign({}, this.form.value);

    const registerUserDto = new RegisterUserDto(
      this.model.email,
      this.model.password
    );

    this.userService.register(registerUserDto)
      .subscribe(
        success => { this.registered() },
        error => { this.runRegisterFail(error) }
      );

    this.userService.saveUserLocalStorage(this.model.email);
  }

  registered() {
    this.userService.authenticate(this.model.email, this.model.password).subscribe(
      success => {
        this.userService.saveJwtLocalStorage(success);
        this.registerToken();
      },
      error => { this.runAuthenticateFail(error) }
    );
  }

  registerToken() {

    this.token = Object.assign({} ,this.tokenService.getLocalStorage());
    this.userId = this.model.email;

    const registerTokenDto = new RegisterTokenDto(
      this.userId,
      this.token.name,
      this.token.symbol,
      this.token.type,
      this.token.decimalPlaces,
      this.token.totalSupply,
      this.token.status
    );

    this.tokenService.register(registerTokenDto)
      .subscribe(
        success => { this.runTokenSuccess() },
        error => { this.runTokenRegisterFail(error) }
      );
  }

  runTokenSuccess() {
    this.tokenService
    .getLastByUser(this.userId)
    .subscribe((response: GetTokenDto) => {
      this.tokenId = response._id
      this.dataService.changeTokenId(this.tokenId)
    });
    this.tokenService.removeTokenLocalStorage();
    this.router.navigate(['/pages/choose-plan']);
    this.spinner.hide();
  }

  runRegisterFail(error: any) {
    const toastr = this.toastr.error('Error', 'Error to register!', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runAuthenticateFail(error: any) {
    const toastr = this.toastr.error('Authenticate Error', 'Error to authenticate!', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runTokenRegisterFail(error: any) {
    const toastr = this.toastr.error('Error', 'Error to register token!', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }
}
