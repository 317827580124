import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  id: string;
  address: string;
  description: string;
  value: string;

  successReturn: string = 'pages/payment-confirmation/success';
  pedingReturn: string = 'pages/payment-confirmation/peding';
  failureReturn: string = 'pages/payment-confirmation/failure';

  baseUrl: string = environment.mercadoPago.url;
  url: string
  urlSafe: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(param => {
      this.id = param.id;
      this.address = param.address;
      this.description = param.desc;
      this.value = param.value;

      this.url = `${this.baseUrl}/${this.id}/${this.address}/bftcreate/${this.description}/${this.value}/`;

      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    });
  }

}
