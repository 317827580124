<article class="d-flex mt-5 px-4 pb-5 px-md-0">
  <div class="containerDashboard">

    <section class="card-shadow px-4 pb-3">
      <div class="py-4 border-bottom">
        <h3 class="font-weight-bold font-size-32 color-blue-first m-0">Dashboard</h3>
      </div>

      <ul class="mt-4 d-flex p-0 mb-0 flex-wrap">
        <li class="border p-3 col-sm-5 mr-3 mb-3 rounded" *ngFor="let token of tokens">

          <h1 class="mb-0 font-archivo font-size-32">
            <span *ngIf="token.status == 'deployed'" class="badge badge-success text-white mr-4">
              <i class="fa fa-check" placement="auto" ngbTooltip="Address: {{ token.address }}"></i>
            </span>
            <span *ngIf="token.status == 'paid'" class="badge badge-warning text-white mr-4">
              <i class="fa fa-exclamation-triangle" placement="auto" ngbTooltip="{{
                        'PAGE.TOKEN_NOT-DEPLOYED_MESSAGE' | translate
                      }}">
              </i>
            </span>

            <span *ngIf="token.status == 'awaitingPayment'" class="badge badge-danger text-white mr-4">
              <i class="fa fa-exclamation-triangle" placement="auto" ngbTooltip="{{
                        'PAGE.TOKEN_AWAITING_PAYMENT_MESSAGE' | translate
                      }}">
              </i>
            </span>
            {{ token.symbol }}
          </h1>

          <div class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mt-3">
            <p class="m-0">
              {{ 'PAGE.NAME' | translate }}
              <span class="token-name">{{ token.name }}</span>
            </p>
            <p class="m-0">
              {{ 'PAGE.TYPE' | translate }}
              <span class="token-name">{{ token.type }}</span>
            </p>
          </div>

          <div class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mt-3 mt-2">
            <p class="m-0">
              {{ 'PAGE.SUPPLY' | translate }}
              <span class="token-name">{{ token.totalSupply }}</span>
            </p>
            <p class="m-0">
              {{ 'PAGE.DECIMALS' | translate }}
              <span class="token-name">{{ token.decimalPlaces }}</span>
            </p>
          </div>

          <ul class="m-0 p-0 mt-4">
            <li class="d-flex flex-column flex-sm-row" *ngIf="token.status == 'deployed'">
              <button class="btn-form btn-secondX" (click)="selectTokenAddress(token.address)" [routerLink]="['/pages/mint-to']">
                Mint
              </button>
              <button class="btn-form bg-red-primary color-white-primary mt-2 mt-sm-0 ml-sm-2" (click)="selectTokenAddress(token.address)" [routerLink]="['/pages/burn-from']">
                Burn
              </button>
            </li>

            <li class="d-flex flex-column flex-sm-row" *ngIf="token.status == 'paid'">
              <button disabled class="btn-form btn-secondX" [routerLink]="['/pages/mint-to']">
                Mint
              </button>
              <button disabled class="btn-form bg-red-primary color-white-primary mx-sm-2 my-2 my-sm-0" [routerLink]="['/pages/burn-from']">
                Burn
              </button>
              <button class="btn-form bg-green-second color-white-primary" (click)="deployToken(token)">
                Deploy Token
              </button>
            </li>

            <li class="d-flex flex-column flex-sm-row" *ngIf="token.status == 'awaitingPayment'">
              <button class="btn-form btn-primaryX mb-2 mb-sm-0 mr-sm-2" (click)="selectTokenId(token._id)" [routerLink]="['/pages/choose-plan']">
                {{ 'PAGE.PLAN_ACTIVATE' | translate }}
              </button>
              <button class="btn-form btn-secondX" (click)="cancelToken(token._id)">
                {{ 'PAGE.CANCEL_BUTTON' | translate }}
              </button>
            </li>

          </ul>

        </li>
      </ul>

      <button [routerLink]="['/pages/create-token-desc']" class="btn-form bg-blue-first font-weight-medium color-white-primary mt-3">+ {{ 'PAGE.CREATE_TOKEN_DASHBOARD' | translate }}</button>

    </section>
  </div>
</article>