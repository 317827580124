import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'ngx-custom-validators';
import { CreateTokenModel } from 'src/app/models/create-token-model';
import { AuthenticationRequestDto } from 'src/app/dtos/authentication-request.dto';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  loginForm: FormGroup;
  model: AuthenticationRequestDto;
  token: CreateTokenModel;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService
  ) {
    this.loginForm = this.formBuilder.group({
      userEmail: ['', [Validators.required]],
      userPassword: ['', [Validators.required, CustomValidators.rangeLength([6, 15])]]
    })
  }

  async onSubmit() {
    this.spinner.show();
    this.model = Object.assign({}, this.loginForm.value);
    this.userService.authenticate(this.model.userEmail, this.model.userPassword).subscribe(
      success => {
        this.userService.saveJwtLocalStorage(success);
        this.userService.saveUserLocalStorage(this.model.userEmail);
        this.spinner.hide();
        this.router.navigate(['pages/dashboard']);
      },
      error => { 
        this.runAuthenticateFail(error);         
        this.spinner.hide();
      })
  }

  runAuthenticateFail(error: any) {
    const toastr = this.toastr.error('Authenticate Error', 'Error to authenticate!', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  redirectToRegister(){
    this.router.navigate(['/']);
  }

}
