import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { GetUserDto } from 'src/app/dtos/get-user.dto';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetTokenDto } from 'src/app/dtos/get-token.dto';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/utils/data.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentModel } from 'src/app/models/payment.model';
import { LicenseModel } from 'src/app/models/license.model';
import { SetupModel } from 'src/app/models/setup.model';
import { LicenseService } from 'src/app/services/license.service';
import { SetupService } from 'src/app/services/setup.service';
import { TokenModel } from 'src/app/models/token.model';
import { TokenService } from 'src/app/services/token.service';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-dashboard-account',
  templateUrl: './dashboard-account.component.html',
  styleUrls: ['./dashboard-account.component.css']
})
export class DashboardAccountComponent implements OnInit {

  user: GetUserDto;
  userId: string;
  payments: PaymentModel[];
  licenses: LicenseModel[];
  setups: SetupModel[];
  tokens: TokenModel[];
  tokenId: string;
  products: Product[];

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private paymentService: PaymentService,
    private licenseService: LicenseService,
    private setupService: SetupService,
    private tokenService: TokenService,
    private productService: ProductService,
    private toastrService: ToastrService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {

    this.spinner.show();
    this.userId = this.userService.getUserLocalStorage();
    this.dataService.currentTokenId.subscribe(tokenId => this.tokenId = tokenId);

    this.userService
      .getByEmail(this.userId)
      .subscribe((response: GetUserDto) => {
        this.user = response
      });

    this.tokenService
      .getByUser(this.userId)
      .subscribe((response: any[]) => {
        this.tokens = response
      });

    this.paymentService
      .getByUser(this.userId)
      .subscribe((response: PaymentModel[]) => {
        this.payments = response
      });

    this.licenseService
      .getLicenseByUser(this.userId)
      .subscribe((response: LicenseModel[]) => {
        this.licenses = response.filter(element => element.statusLicense == true)
      });

    this.setupService
      .getSetupByUser(this.userId)
      .subscribe((response: SetupModel[]) => {
        this.setups = response.filter(element => element.statusSetup == true)
      });

      this.productService
      .getProduct()
      .subscribe((response: Product[]) => {
        this.products = response
      });

    this.spinner.hide();
  }

  selectTokenId(newId: string) {
    this.dataService.changeTokenId(newId)
  }

  getProductName(productId:number){
    this.productService
    .getProduct()
    .subscribe((response: Product[]) => {
      const product = response.filter(element => element.id == productId)
      return product[0].name
    });
  }

  getTokenName(tokenId:string){
    this.tokenService
    .getById(tokenId)
    .subscribe((response: GetTokenDto) => {
      const token = response
      return token.name
    });
  }


}
