import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { CreateTokenModel } from '../../models/create-token-model';
@Component({
  selector: 'app-create-token-details',
  templateUrl: './create-token-details.component.html',
  styleUrls: ['./create-token-details.component.css']
})
export class CreateTokenDetailsComponent implements OnInit {

  token:  CreateTokenModel[];

  constructor(
    private router: Router,
    private userSevice: UserService
    ) { }

  ngOnInit(): void {
    this.userSevice
    .getByEmail('')
    .subscribe((createTokenModel:any) => {
      this.token = createTokenModel.token
    })
  }

  redirectToMintBurn() {
    this.router.navigate(['/pages/mint-burn']);

  }

}
