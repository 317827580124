import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/services/token.service';
import { UserService } from 'src/app/services/user.service';
import { CreateTokenModel, TokenStatus } from 'src/app/models/create-token-model';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { RegisterTokenDto } from 'src/app/dtos/register-token-dto';
import { DataService } from 'src/app/utils/data.service';
import { GetTokenDto } from 'src/app/dtos/get-token.dto';

@Component({
  selector: 'app-create-token-resume',
  templateUrl: './create-token-resume.component.html',
  styleUrls: ['./create-token-resume.component.css']
})
export class CreateTokenResumeComponent implements OnInit {

  logged: boolean;
  userId: string;
  tokenId: string;

  token: CreateTokenModel = new CreateTokenModel(
    '', '', '', '', 0, 0, TokenStatus.awaitingPayment
  );

  constructor(
    private tokenService: TokenService,
    private userService: UserService,
    private dataService: DataService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.spinner.show();

    this.dataService.currentTokenId.subscribe(tokenId => this.tokenId = tokenId);

    if (this.tokenService.isNotSaved()) {
      const toastr = this.toastr.error('Error', 'Token is not defined!', {
        progressBar: true
      });

      if (toastr) {
        toastr.onHidden.subscribe(() => {
          this.spinner.hide();
          this.router.navigate(['/pages/create-token-desc']);
        });
      }
    } else {
      this.token = this.tokenService.getLocalStorage();
      this.spinner.hide();
    }
  }

  registerToken() {

    this.spinner.show();
    this.logged = this.userService.isLogged();

    if (!this.logged) {

      this.spinner.hide();
      this.router.navigate(['/pages/create-token-register']);

    } else {
      this.token = Object.assign({}, this.tokenService.getLocalStorage());
      this.userId = this.userService.getUserLocalStorage();

      const registerTokenDto = new RegisterTokenDto(
        this.userId,
        this.token.name,
        this.token.symbol,
        this.token.type,
        this.token.decimalPlaces,
        this.token.totalSupply,
        this.token.status
      );

      this.tokenService.register(registerTokenDto)
        .subscribe(
          success => { this.runSuccess() },
          error => { this.runRegisterFail(error) }
        );

      this.tokenService
        .getLastByUser(this.userId)
        .subscribe((response: GetTokenDto) => {
          this.tokenId = response._id
          this.dataService.changeTokenId(this.tokenId)
        });
    }
  }

  runSuccess() {
    this.router.navigate(['/pages/choose-plan']);
    this.spinner.hide();
  }

  runRegisterFail(error: any) {
    const toastr = this.toastr.error('Error', 'Error to register token!', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.tokenService.removeTokenLocalStorage();
        this.spinner.hide();
      });
  }

}
