<section class="bg-summary">
  <div class="containerX p-3">
    <div class="mb-5">
      <h1 class="
          text-center
          font-archivo font-size-44
          color-blue-third
          font-weight-semibold
        ">
        Create token / {{ 'PAGE.SUMMARY' | translate }}
      </h1>
      <p class="text-center pb-5 col-12 col-sm-9 mx-auto">
        {{ 'PAGE.SUMMARY_DESCRIPTION' | translate }}
      </p>
    </div>
    <form class="card-shadow mx-auto w-100 col-sm-10">
      <section class="form-group py-5">
        <div class="d-block d-sm-flex justify-content-center">
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="name">{{ 'PAGE.NAME' | translate }}</label>
            <input type="text" class="form-control" disabled value="{{ token.name }}" />
          </div>
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="symbol">{{ 'PAGE.SYMBOL' | translate }}</label>
            <input type="text" class="form-control" disabled value="{{ token.symbol }}" />
          </div>
        </div>
        <div class="d-block d-sm-flex justify-content-center">
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="type">{{ 'PAGE.TYPE' | translate }}</label>
            <input type="text" class="form-control" disabled value="{{ token.type }}" />
          </div>
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="decimals">{{ 'PAGE.DECIMALS' | translate }}</label>
            <input type="text" class="form-control" disabled value="{{ token.decimalPlaces }}" />
          </div>
        </div>
        <div class="d-block d-sm-flex justify-content-center">
          <div class="col-12 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="decimals">{{ 'PAGE.SUPPLY' | translate }}</label>
            <input type="text" class="form-control" disabled value="{{ token.totalSupply }}" />
          </div>
        </div>
        <div class="d-block d-sm-flex col-12 py-2">
          <button class="btn btn-secondX w-100 col-sm mr-4 my-2 my-lg-0" [routerLink]="['/pages/create-token-inter']">
            {{ 'PAGE.BACK' | translate }}
          </button>

          <button class="btn btn-primaryX w-100 col-sm my-2 my-lg-0" (click)="registerToken()">
            {{ 'PAGE.DEPLOY_TOKEN' | translate }}
          </button>
        </div>
      </section>
    </form>
  </div>
</section>

<footer class="text-center py-4">
  <p class="m-0 color-blue-third font-italic font-weight-medium font-size-14">
    © BFT Solutions - Todos os direitos reservados
  </p>
</footer>