import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { UserService } from 'src/app/services/user.service';
import { GetUserDto } from 'src/app/dtos/get-user.dto';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetTokenDto } from 'src/app/dtos/get-token.dto';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/utils/data.service';

@Component({
  selector: 'app-dashboard-token',
  templateUrl: './dashboard-token.component.html',
  styleUrls: ['./dashboard-token.component.css']
})
export class DashboardTokenComponent implements OnInit {

  user: GetUserDto;
  userId: string;
  tokens: GetTokenDto[];
  tokenAddress: string;
  tokenId: string;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private tokenService: TokenService,
    private toastrService: ToastrService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {

    this.spinner.show();
    this.userId = this.userService.getUserLocalStorage();
    this.dataService.currentTokenAddress.subscribe(tokenAddress => this.tokenAddress = tokenAddress);
    this.dataService.currentTokenId.subscribe(tokenId => this.tokenId = tokenId);

    this.userService
      .getByEmail(this.userId)
      .subscribe((response: GetUserDto) => {
        this.user = response
      });

    this.tokenService
      .getByUser(this.userId)
      .subscribe((response: GetTokenDto[]) => {
        this.tokens = response
      });

    this.spinner.hide();
  }

  deployToken(token: GetTokenDto){
    this.spinner.show();
    this.tokenService.deploy(this.user.email, token._id)
    .subscribe(
      success => { this.runSuccess() },
      error => { this.runError(error) }
    );
  }

  runSuccess() {
    const toastr = this.toastrService.success(`Deploy success`, 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.ngOnInit();
        this.spinner.hide();
      });
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  selectTokenAddress(newAddress: string){
    this.dataService.changeTokenAddress(newAddress)
  }

  selectTokenId(newId: string){
    this.dataService.changeTokenId(newId)
  }

}
