import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenService } from '../../services/token.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { DataService } from 'src/app/utils/data.service';
import { PaymentService } from 'src/app/services/payment.service';
import { RegisterPaymentDto } from 'src/app/dtos/register-payment.dto';
import { SetupService } from 'src/app/services/setup.service';
import { LicenseService } from 'src/app/services/license.service';
import { GetTokenDto } from 'src/app/dtos/get-token.dto';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.css']
})
export class PaymentConfirmationComponent implements OnInit {

  paymentStatus: string;
  paymentText: string;
  userId: string;
  tokenId: string;
  payment: RegisterPaymentDto;
  token;
  preSelectedProductsIds: number[];
  newProductsIds: number[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private tokenService: TokenService,
    private setupService: SetupService,
    private licenseService: LicenseService,
    private paymentService: PaymentService,
    private toastrService: ToastrService,
    private dataService: DataService
  ) { }

  getDate() {
    let date_ob = new Date();
    let day = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    return year + "-" + month + "-" + day;
  }

  ngOnInit(): void {
    this.route.params.subscribe(param => {

      this.spinner.show();

      this.payment = this.paymentService.getPaymentLocalStorage();
      this.preSelectedProductsIds = this.tokenService.getOldProductsLocalStorage();

      this.userId = this.payment.userId
      const _id = this.payment.tokenId;
      this.paymentStatus = param.status;
      const date = this.getDate();

      switch (this.paymentStatus) {
        case 'success':
          this.paymentText = 'Congrats! Yor payment was approved!Your token is being deployed, in a few seconds you will be redirect to your Dashboard!';
          this.paymentService.registerPayment(this.payment)
            .subscribe(
              success => { this.runPaymentSuccess() },
              error => { this.runPaymentError(error) }
            );
          this.setupService.registerSetup(this.payment.tokenId, this.userId, date, this.payment.products)
            .subscribe(
              success => { this.runSetupSuccess() },
              error => { this.runSetupError(error) }
            );
          this.licenseService.registerLicense(this.payment.tokenId, this.userId, date, this.payment.products)
            .subscribe(
              success => { this.runLicenseSuccess() },
              error => { this.runLicenseError(error) }
            );
          if (this.preSelectedProductsIds == undefined) {
            this.newProductsIds = this.payment.products
            const updateProductsToken = this.tokenService.updateProduct(_id, this.newProductsIds)
              .subscribe(
                success => { this.runUpdatePaymentTokenSuccess() },
                error => { this.runUpdatePaymentTokenError(error) }
              );
            this.paymentService.removePaymentLocalStorage();
            this.tokenService.removeOldProductsLocalStorage();
            this.tokenService.removeTokenLocalStorage();
            break;
          } else {
            this.newProductsIds = this.preSelectedProductsIds.concat(this.payment.products)
            const updateProductsToken = this.tokenService.updateProduct(_id, this.newProductsIds)
              .subscribe(
                success => { this.runUpdateProductsTokenSuccess() },
                error => { this.runUpdateProductsTokenError(error) }
              );
            this.paymentService.removePaymentLocalStorage();
            this.tokenService.removeOldProductsLocalStorage();
            this.tokenService.removeTokenLocalStorage();
            break;
          }
        case 'failure':
          this.paymentText = 'Ops, there was a problem with your payment! Try it again!';
          this.runFailure();
          break;
        case 'pending':
          this.paymentText = 'Your payment is pending, you must be wait for a response!';
          this.paymentService.removePaymentLocalStorage();
          this.runPending();
          break;
      }
    });
  }

  runUpdatePaymentTokenSuccess() {
    const toastr = this.toastrService.success(`Updated Token Payment Status`, 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runUpdatePaymentTokenError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error to Update Token Payment Status', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runUpdateProductsTokenSuccess() {
    const toastr = this.toastrService.success(`Updated Products`, 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runUpdateProductsTokenError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error to Update Product', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runPaymentSuccess() {
    const toastr = this.toastrService.success(`Payment Approved`, 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runPaymentError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error Payment Register', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runSetupSuccess() {
    const toastr = this.toastrService.success(`Setup saved`, 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runSetupError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runLicenseSuccess() {
    const toastr = this.toastrService.success(`License created`, 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.router.navigate(['/pages/dashboard']);
        this.spinner.hide();
      });
  }

  runLicenseError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }

  runFailure() {
    const toastr = this.toastrService.error(`Payment Failed, try again`, 'Failure', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.router.navigate(['pages/payment', this.payment.tokenId, this.userId, "CreateToken", this.payment.paymentValue]);
        this.spinner.hide();
      });
  }

  runPending() {
    const toastr = this.toastrService.error(`Payment Pending, wait`, 'Pending', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.router.navigate(['/pages/dashboard']);
        this.spinner.hide();
      });
  }

}
