<section class="bg-register">
  <div class="containerX p-3">
    <div class="mb-5">
      <h1 class="
          text-center
          font-archivo font-size-44
          color-blue-third
          font-weight-semibold
        ">
        Create token / {{ 'PAGE.REGISTER_USER' | translate }}
      </h1>
      <p class="text-center pb-5 col-12 col-sm-9 mx-auto">
        {{ 'PAGE.REGISTER' | translate }}
      </p>
    </div>
    <section class="d-block d-sm-flex justify-content-center">
      <form class="card-shadow col pt-5" (ngSubmit)="register()" [formGroup]="form">
        <div class="col mb-5">
          <h5 class="
              font-inter font-size-32
              color-blue-third
              font-weight-semibold
            ">
            {{ 'PAGE.NEW_USER' | translate }}
          </h5>
          <p class="color-brown-primary font-inter">
            To access MustCreate register your account or
            <a href="javascript:void(0"><u class="color-brown-primary font-inter">{{
                'PAGE.FORGOT_PASSWORD' | translate
                }}</u></a>
          </p>
        </div>
        <div class="form-group">
          <div class="col">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="email">E-mail</label>
            <input type="text" class="form-control" id="email" name="email" formControlName="email" [ngClass]="{
                'is-invalid':
                  form.controls.email.invalid && form.controls.email.dirty,
                'is-valid': form.controls.email.valid
              }" />
            <span class="text-danger" *ngIf="form.controls.email.invalid && form.controls.email.dirty">
              <p *ngIf="form.controls.email.errors.required">
                E-mail is required!
              </p>
            </span>
          </div>
        </div>
        <div class="form-group">
          <div class="col">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="password">{{ 'PAGE.PASSWORD' | translate }}</label>
            <input type="password" class="form-control" id="password" name="password" formControlName="password" [ngClass]="{
                'is-invalid':
                  form.controls.password.invalid &&
                  form.controls.password.dirty,
                'is-valid': form.controls.password.valid
              }" />
            <span class="text-danger" *ngIf="
                form.controls.password.invalid && form.controls.password.dirty
              ">
              <p *ngIf="form.controls.password.errors.required">
                Password is required!
              </p>
              <p *ngIf="form.controls.password.errors.rangeLength">
                Password must be between 6 and 15 characters!
              </p>
            </span>
          </div>
        </div>
        <div class="form-group">
          <div class="col">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="confirmPassword">{{ 'PAGE.CONFIRM_PASSWORD' | translate }}</label>
            <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" formControlName="confirmPassword" [ngClass]="{
                'is-invalid':
                  form.controls.confirmPassword.invalid &&
                  form.controls.confirmPassword.dirty,
                'is-valid': form.controls.confirmPassword.valid
              }" />
            <span class="text-danger" *ngIf="
                form.controls.confirmPassword.invalid &&
                form.controls.confirmPassword.dirty
              ">
              <p *ngIf="form.controls.confirmPassword.errors.required">
                Confirm password is required!
              </p>
              <p *ngIf="form.controls.confirmPassword.errors.rangeLength">
                Password must be between 6 and 15 characters!
              </p>
              <p *ngIf="form.controls.confirmPassword.errors.equalTo">
                Confirm password must be equal password!
              </p>
            </span>
            <button class="btn btn-primaryX col my-4" [disabled]="form.invalid">
              {{ 'PAGE.REGISTER_USER' | translate }}
            </button>
          </div>
        </div>
      </form>
      <div class="col">
        <img src="../../../assets/img-bft/img-register.svg" alt="" />
      </div>
    </section>
  </div>
</section>
<footer class="text-center py-4">
  <p class="m-0 color-blue-third font-italic font-weight-medium font-size-14">
    © BFT Solutions - Todos os direitos reservados
  </p>
</footer>