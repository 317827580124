<section class="mt-4 img-must">
  <div>
    <h1 class="text-center color-blue-second font-archivo font-weight-semibold mb-4 font-size-44">{{ 'PAGE.CHOOSE' | translate}}</h1>
    <h2 class="font-size-16 col-sm-5 m-auto color-blue-first font-poppins text-center">We offer several products to build your plan, choose and come with us!</h2>
  </div>

  <div class="containerX">
    <form [formGroup]="form" (ngSubmit)="registerPlan()" class="card-shadow choose-responsivo p-4 mt-5">
      <table class="table table-responsive-sm">
        <thead>
          <tr>
            <th scope="col" class="font-inter color-blue-first font-size-14 table-button font-weight-semibold">{{ 'PAGE.CHOOSE_TABLE_PRODUCT' | translate}}</th>
            <th scope="col" class="text-center font-inter color-blue-first table-button font-size-14 font-weight-semibold">{{ 'PAGE.CHOOSE_TABLE_CHOOSE' | translate}}</th>
            <th scope="col" class="text-center font-inter color-blue-first table-button font-size-14 font-weight-semibold">{{ 'PAGE.CHOOSE_TABLE_DESC' | translate}}</th>
            <th scope="col" class="text-center font-inter color-blue-first table-button font-size-14 font-weight-semibold">{{ 'PAGE.CHOOSE_TABLE_PRICE_SETUP' | translate}}
            </th>
            <th scope="col" class="text-center font-inter color-blue-first font-size-14 font-weight-semibold">
              {{ 'PAGE.CHOOSE_TABLE_PRICE_MONTHLY' | translate}}
            </th>
        </thead>
        <tbody class="font-inter font-size-14 color-blue-second">
          <tr formArrayName="products" *ngFor="let product of productsFormArray.controls; let i = index">
            <th scope="row">{{productsData[i].name}}</th>
            <td class="text-center custom-control custom-switch"><label class="switch"><input (change)="FieldsChange($event, productsData[i].priceSetup, productsData[i].priceMonthly)" value="angular" checked data-size="sm" class="big-checkbox" type="checkbox" [formControlName]="i">
                <span class="slider round"></span></label></td>
            <td class="text-center"><i class="fas fa-info-circle" placement="auto" ngbTooltip="{{productsData[i].description}}"></i></td>
            <td class="text-center">R$ {{productsData[i].priceSetup}},00</td>
            <td class="text-center">R$ {{productsData[i].priceMonthly}},00</td>
          </tr>

        </tbody>

      </table>
      <div>
        <h5 class="mt-3 col font-weight-semibold font-size-16 table-button d-flex modal-header font-poppins"> {{ 'PAGE.TOTAL_SETUP' | translate}} R$
          {{totalSetupPrice}},00 + {{ 'PAGE.TOTAL_MONTHLY' | translate}}
          R$ {{totalMonthlyPrice}},00
          <button class="btnX bg-green font-size-16 font-poppins respon-button color-green-choose d-flex"><img src="../../../assets/img-bft/Group 840.svg" style="width: 30px;" class="d-flex img-button">
            <p class="m-auto respon-button button-text">{{ 'PAGE.CHOOSE_BUTTON' | translate}}</p>
          </button>
        </h5>
      </div>
    </form>
  </div>
</section>
<footer class="text-center py-4">
  <p class="m-0 color-blue-third font-italic font-weight-medium font-size-14">
    © BFT Solutions - Todos os direitos reservados
  </p>
</footer>