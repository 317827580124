export class CreateTokenModel {
    constructor(
        public userId: string,
        public name: string,
        public symbol: string,
        public type: string,
        public decimalPlaces: number,
        public totalSupply: number,
        public status: TokenStatus
    ) { }
}

export enum TokenStatus {
    awaitingPayment = 'awaitingPayment',
    paid = 'paid',
    deployed = 'deployed'
}
