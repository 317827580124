<section class="bg-login">
  <div class="containerX p-3">
    <div class="mb-5">
      <h1 class="
          text-center
          font-archivo font-size-44
          color-blue-third
          font-weight-semibold
        ">
        Login
      </h1>
      <p class="text-center pb-5 col-12 col-sm-9 mx-auto">
        {{ 'PAGE.LOGIN_TEXT' | translate }}
      </p>
    </div>
    <section class="d-block d-sm-flex justify-content-center">
      <form class="col card-shadow" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="col-md-12">
          <div class="py-5">
            <h3 class="
                font-inter font-size-32
                color-blue-third
                font-weight-semibold
                pt-4
              ">
              {{ 'PAGE.LOGIN_SIGN' | translate }}
              
            </h3>
            <p class="col-sm-11 pl-0 color-brown-primary font-inter m-0">
              {{ 'PAGE.LOGIN_SIGN_TEXT' | translate }}
              <a (click)="redirectToRegister()"><u class="color-brown-primary font-inter">{{ 'PAGE.REGISTER_TEXT' | translate }}</u></a>
            </p>
          </div>

          <label class="font-archivo font-size-18 font-weight-medium m-0" for="userEmail">E-mail</label>
          <input type="text" class="form-control form_input" id="userEmail" name="userEmail" formControlName="userEmail" />
        </div>
        <div class="col-md-12">
          <label class="font-archivo font-size-18 font-weight-medium m-0" for="userPassword">Password</label>
          <input type="password" class="form-control form_input" id="userPassword" name="userPassword" formControlName="userPassword" />
        </div>
        <div class="col-md-12">
          <button type="submit" class="btn btn-primaryX col my-4">Login</button>
        </div>
      </form>
      <div class="col mt-5 mt-sm-0">
        <img src="../../../assets/img-bft/img-home.svg" alt="" />
      </div>
    </section>
  </div>
</section>
<footer class="text-center py-4">
  <p class="m-0 color-blue-third  font-weight-medium font-size-14">
    © BFT Solutions - Todos os direitos reservados
  </p>
</footer>