import { Component, OnInit } from '@angular/core';
import { CreateTokenModel } from 'src/app/models/create-token-model';

@Component({
  selector: 'app-create-token-interface',
  templateUrl: './create-token-interface.component.html',
  styleUrls: ['./create-token-interface.component.css']
})
export class CreateTokenInterfaceComponent implements OnInit {

  model: CreateTokenModel;

  constructor() { }

  ngOnInit(): void {
  }
}
