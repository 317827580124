<div class="container-fluid">

  <main role="main" class="col-md-12 ml-sm-auto col-lg-12 pt-3 px-4">

    <section class="row">
      <div class="col-md-3">
        <div class="sidebar">
          <a [routerLink]="['/pages/dashboard']"><i class="fa fa-columns mr-2"></i> Dashboard</a>
          <a [routerLink]="['/pages/dashboard-token']"><i class="fa fa-cubes mr-2"></i>Tokens</a>
          <a [routerLink]="['/pages/dashboard-plan']" class="active"><i
              class="fa fa-shopping-basket mr-2"></i>{{ 'PAGE.PLAN_DASHBOARD' | translate }}</a>
          <a [routerLink]="['/pages/dashboard-account']"><i
              class="fa fa-user mr-2"></i>{{ 'PAGE.ACCOUNT_DASHBOARD' | translate }}</a>
          <br>
          <a disabled>{{ 'PAGE.USER_LOGGED_DASHBOARD' | translate }}<br>{{ user?.email }}</a>
        </div>
      </div>
      <div class="col-md-9">
        <a class="card shadow p-2 bg-theme rounded mt-4">
          <div class="mt-2">
            <div class="row text-center text-white align-items-center">
              <div class="col-md-12">

                <h4>Edit {{token.symbol}} Plan</h4>

              </div>
            </div>
          </div>
        </a>
        <form [formGroup]="form">
          <div class="card">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="text-white bg-secondary">{{ 'PAGE.CHOOSE_TABLE_PRODUCT' | translate}}</th>
                  <th scope="col" class="text-center text-white bg-secondary">
                    {{ 'PAGE.CHOOSE_TABLE_CHOOSE' | translate}}
                  </th>
                  <th scope="col" class="text-center text-white bg-secondary">{{ 'PAGE.CHOOSE_TABLE_DESC' | translate}}
                  </th>
                  <th scope="col" class="text-center text-white bg-secondary">
                    {{ 'PAGE.CHOOSE_TABLE_PRICE_SETUP' | translate}}
                  </th>
                  <th scope="col" class="text-center text-white bg-secondary">
                    {{ 'PAGE.CHOOSE_TABLE_PRICE_MONTHLY' | translate}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr formArrayName="products" *ngFor="let product of productsFormArray.controls; let i = index">
                  <th scope="row">{{productsData[i].name}}</th>
                  <td *ngIf="preSelectedProductsIds.includes(productsData[i].id)"
                    class="text-center custom-control custom-switch"><label class="switch">
                      <div class="big-checkbox" data-size="sm" (click)="cancelLicense(productsData[i].id)"><span
                          class="slider-cancel round"></span></div>
                    </label></td>
                  <td *ngIf="!preSelectedProductsIds.includes(productsData[i].id)"
                    class="text-center custom-control custom-switch"><label class="switch"><input
                        (change)="FieldsChange($event, productsData[i].priceSetup, productsData[i].priceMonthly)"
                        value="angular" checked data-size="sm" class="big-checkbox" type="checkbox"
                        [formControlName]="i"> <span class="slider round"></span></label></td>
                  <td class="text-center"><i class="fas fa-lg fa-info-circle" placement="auto"
                      ngbTooltip="{{productsData[i].description}}"></i></td>
                  <td *ngIf="preSelectedProductsIds.includes(productsData[i].id)" class="text-center">
                    <span class="badge badge-success">
                      {{ 'PAGE.ACTIVE' | translate}}
                    </span>
                  </td>
                  <td *ngIf="!preSelectedProductsIds.includes(productsData[i].id)" class="text-center">R$
                    {{productsData[i].priceSetup}},00</td>
                  <td class="text-center">R$ {{productsData[i].priceMonthly}},00</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 text-center">
              <h5 class="mt-3">
                <i class="fas fa-shopping-cart mr-3"></i>
                {{ 'PAGE.NEW_SETUP' | translate}} R$ {{totalSetupPrice}},00 + {{ 'PAGE.NEW_MONTHLY' | translate}} R$ {{totalMonthlyPrice}},00
                <button class="btn btn-success ml-4 mt-1" (click)="updatePlan()">Upgrade Plan</button>
              </h5>
            </div>
          </div>
        </form>
      </div>
    </section>

  </main>

</div>
