<article class="d-flex mt-5 px-4 pb-5 px-md-0">
  <div class="containerDashboard">

    <section class="card-shadow px-4 pb-3">
      <div class="py-4 border-bottom">
        <h3 class="font-weight-bold font-size-32 color-blue-first m-0">Tokens</h3>
      </div>

      <div class="mt-4 table-responsive">
        <!-- Table -->
        <table class="table table-striped" id="tableX">
          <thead>
            <tr scope="col">
              <th class="py-3 border-0">{{ 'PAGE.SYMBOL' | translate}}</th>
              <th class="py-3 border-0">{{ 'PAGE.NAME' | translate}}</th>
              <th class="py-3 border-0">{{ 'PAGE.TYPE' | translate}}</th>
              <th class="py-3 border-0">{{ 'PAGE.SUPPLY' | translate}}</th>
              <th class="py-3 border-0">{{ 'PAGE.DECIMALS' | translate}}</th>
              <th class="py-3 border-0"></th>
              <th class="py-3 border-0"></th>
            </tr>
          </thead>

          <tbody>
            <tr scope="row" class="py-1" *ngFor="let token of tokens" style="line-height: 30px;">

              <td class="">{{token.symbol}}</td>
              <td class="">{{token.name}}</td>
              <td class="">{{token.type}}</td>
              <td class="">{{token.totalSupply}}</td>
              <td class="">{{token.decimalPlaces}}</td>
              <td>
                <span *ngIf="token.status == 'deployed'" class="badge badge-success text-white mr-4 p-2"><i class="fa fa-check" placement="auto" ngbTooltip="Address: {{token.address}}"></i>
                </span>
                <span *ngIf="token.status == 'paid'" class="badge badge-warning text-white mr-4 p-2"><i class="fa fa-exclamation-triangle" placement="auto" ngbTooltip="{{ 'PAGE.TOKEN_NOT-DEPLOYED_MESSAGE' | translate }}"></i>
                </span>
                <span *ngIf="token.status == 'awaitingPayment'" class="badge badge-danger text-white mr-4 p-2"><i class="fa fa-exclamation-triangle" placement="auto" ngbTooltip="{{ 'PAGE.TOKEN_AWAITING_PAYMENT_MESSAGE' | translate }}"></i>
                </span>
              </td>
              <td>
                <a *ngIf="token.status == 'awaitingPayment'" class="btn btn-sm text-white btn-primaryX float-right" (click)="selectTokenId(token._id)" [routerLink]="['/pages/choose-plan']">Activate Your Plan</a>
                <a *ngIf="token.status == 'paid'" class="btn btn-sm text-white btn-success float-right" (click)="deployToken(token)">Deploy Token</a>
                <div class="float-right" *ngIf="token.status == 'deployed'" ngbDropdown #myDrop="ngbDropdown">
                  <button class="btn btn-sm btn-hero" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">Action</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManual">
                    <button ngbDropdownItem (click)="selectTokenAddress(token.address)" [routerLink]="['/pages/mint-to']">Mint {{token.symbol}}</button>
                    <button ngbDropdownItem (click)="selectTokenAddress(token.address)" [routerLink]="['/pages/burn-from']">Burn {{token.symbol}}</button>
                  </div>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
        <!-- FIM Table  -->
      </div>

      <button [routerLink]="['/pages/create-token-desc']" class="btn-form bg-blue-first font-weight-medium color-white-primary mb-3 mt-3">+ {{ 'PAGE.CREATE_TOKEN_DASHBOARD' | translate }}</button>
    </section>
  </div>
</article>