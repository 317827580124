import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { LicenseModel } from '../models/license.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
//import { RegisterLicenseDto } from '../dtos/register-license.dto';

@Injectable()

export class LicenseService extends BaseService {
  constructor(
    private readonly httpClient: HttpClient
  ) {
    super();
  }

  getLicenseByUser(userId: string): Observable<LicenseModel[]> {
    return this.httpClient
      .get(`${this.url}/license/user/${userId}`, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError));
  }

  getLicenseByToken(tokenId: string): Observable<LicenseModel[]> {
    return this.httpClient
      .get(`${this.url}/license/token/${tokenId}`, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError));
  }

  registerLicense(tokenId: string, userId: string, dateLicense: string, selectedProductsIds: number[]): Observable<any> {
    return this.httpClient
      .post(`${this.url}/license/create`, { tokenId, userId, dateLicense, selectedProductsIds }, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError));
  }

  updateLicenseStatus(tokenId: string) {
    const response = this.httpClient
      .post(`${this.url}/license/update-status-active`, { tokenId }, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
    return response;
  }

  deleteLicense(tokenId: string, productId: number) {
    const response = this.httpClient
      .post(`${this.url}/license/delete`, { tokenId, productId }, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
    return response;
  }

}
