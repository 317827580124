import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-token',
  templateUrl: './create-token.component.html',
  styleUrls: ['./create-token.component.css']
})
export class CreateTokenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
