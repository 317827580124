export const environment = {
  production: true,
  encryptHey: '56d939a3-ef5e-4290-9f39-9ed4ef88006b',
  api: {
    url: 'https://api.bft.solutions/api/create/',
    port: ''
  },
  mercadoPago:{
    url: 'https://api.bft.solutions/api/mercadopago/payments/checkout'
  }
};