import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Product } from '../models/product';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";

@Injectable()

export class ProductService extends BaseService {
    constructor(
        private readonly httpClient: HttpClient
    ) {
        super();
    }

    getProduct(): Observable<Product[]> {
        const response = this.httpClient
            .get<Product[]>(`${this.url}/product`)
            .pipe(
                map(this.extractData),
                catchError(this.serviceError));
        return response;
    }
}
