<form (ngSubmit)="mint()" [formGroup]="form">
    <div class="col-md-6 offset-md-3">
        <h5 class="page-title">Mint {{token.name}}</h5>
        <p class="page-token">{{ 'PAGE.MINT_DETAILS' | translate }}</p>
    </div>
    <div class="form-group">
        <div class="col-md-6 offset-md-3">
            <label for="address">Address</label>
            <input type="text" class="form-control" id="address" name="address" formControlName="address"
                [ngClass]="{ 'is-invalid': form.controls.address.invalid && form.controls.address.dirty, 'is-valid': form.controls.address.valid }" />
            <span class="text-danger" *ngIf="form.controls.address.invalid && form.controls.address.dirty">
                <p *ngIf="form.controls.address.errors.required">{{ 'PAGE.ADDRESS_REQUIRE_MESSAGE' | translate }}</p>
            </span>
        </div>
    </div>
    <div class="form-group">
        <div class="col-md-6 offset-md-3">
            <label for="value">Value</label>
            <input type="value" class="form-control" id="value" name="value" formControlName="value"
                [ngClass]="{ 'is-invalid': form.controls.value.invalid && form.controls.value.dirty, 'is-valid': form.controls.value.valid }" />
            <span class="text-danger" *ngIf="form.controls.value.invalid && form.controls.value.dirty">
                <p *ngIf="form.controls.value.errors.required">{{ 'PAGE.VALUE_REQUIRE_MESSAGE' | translate }}</p>
                <p *ngIf="form.controls.value.errors.number">{{ 'PAGE.VALUE_REQUIRE_MESSAGE' | translate }}</p>
            </span>
        </div>
    </div>
    <div class="form-group">
        <div class="text-center">
            <button type="button" class="btn btn-lg" [routerLink]="['/pages/dashboard']">{{ 'PAGE.BACK_BUTTON' | translate }}</button>
            <button type="submit" class="btn btn-lg btn-hero ml-3" [disabled]="form.invalid">Mint</button>
        </div>
    </div><br><br>
    <div class="text-center">
        <img src="../../../assets/img/undraw_make_it_rain_iwk4.svg" width="258" class="img-fluid mt-5" alt="undraw">
    </div>

    