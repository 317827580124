import { Component, OnInit, Inject, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  public isCollapsed: boolean;
  isToggled: boolean;
  isLogged: boolean;

  constructor(
    public translate: TranslateService, 
    private userService: UserService, 
    private router: Router
    ) {

    this.isCollapsed = true;

    translate.addLangs(['en', 'pt']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|pt/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.isLogged = this.userService.isLogged();
  }
}