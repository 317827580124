import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'app-nav-logged',
  templateUrl: './nav-logged.component.html',
  styleUrls: ['./nav-logged.component.css']
})
export class NavLogComponent implements OnInit {

  isCollapsed: boolean;
  isToggled: boolean;
  isLogged: boolean;

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private sidebarService: SidebarService
  ) {
    translate.addLangs(['en', 'pt']);
    translate.setDefaultLang('en');

    this.isCollapsed = true;

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|pt/) ? browserLang : 'en');
  }

  ngOnInit(): void {
   
  }

  toggleSidebar() {
    this.isToggled = !this.isToggled;
    this.sidebarService.changeVisibility(this.isToggled);
  }

  logoff() {
    this.userService.logout();
    this.router.navigate(['/']);
  }

}
