<section class="col-md-12">
    <div class="card shadow p-3 bg-white rounded mt-5">
        <div class="texts">
            <p class="title-token">{{ 'PAGE.NAME' | translate }} <span class="token-name">&nbsp;</span></p>
            <p class="title-token">{{ 'PAGE.SYMBOL' | translate }} <span class="token-name">&nbsp;</span></p>
            <p class="title-token">{{ 'PAGE.TYPE' | translate }} <span class="token-name">&nbsp;</span></p>
            <p class="title-token">{{ 'PAGE.DECIMALS' | translate }} <span class="token-name">&nbsp;</span></p>
            <p class="title-token">{{  'PAGE.SUPPLY' | translate }} <span class="token-name">&nbsp;</span></p>
            <p class="title-token">{{ 'PAGE.STATUS' | translate }} <span class="token-name">&nbsp;</span></p>
        </div>
    </div>
</section>