import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { TokenService } from 'src/app/services/token.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MintResponseDto } from 'src/app/dtos/mint-response.dto';
import { ToastrService } from 'ngx-toastr';
import { MintRequestDto } from 'src/app/dtos/mint-request.dto';
import { DataService } from 'src/app/utils/data.service';
import { UserService } from 'src/app/services/user.service';
import { GetUserDto } from 'src/app/dtos/get-user.dto';
import { GetTokenDto } from 'src/app/dtos/get-token.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mint-to',
  templateUrl: './mint-to.component.html',
  styleUrls: ['./mint-to.component.css']
})
export class MintToComponent implements OnInit {

  user: GetUserDto;
  userId: string;
  form: FormGroup;
  request: MintRequestDto;
  token: GetTokenDto;
  tokenAddress: string;

  constructor(
    private fb: FormBuilder,
    private tokenService: TokenService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dataService: DataService,
    private router: Router
  ) {

  }

  ngOnInit(): void {

    this.spinner.show();

    this.userId = this.userService.getUserLocalStorage();
    this.dataService.currentTokenAddress.subscribe(tokenAddress => this.tokenAddress = tokenAddress);

    this.userService
      .getByEmail(this.userId)
      .subscribe((response: GetUserDto) => {
        this.user = response;
      });

    this.tokenService
      .getByAddress(this.tokenAddress)
      .subscribe((response: GetTokenDto) => {
        this.token = response[0]
      });

      this.form = this.fb.group({
        userId: this.userId,
        tokenAddress: this.tokenAddress,
        address: ['', [Validators.required]],
        value: ['', [Validators.required, CustomValidators.number]],
      });

    this.spinner.hide();

  }

  mint() {
    this.spinner.show();

    this.request = Object.assign({}, this.form.value);

    this.tokenService.mint(this.request).subscribe(
      success => { this.runSuccess(success) },
      error => { this.runError(error) }
    );
  }

  runSuccess(success: MintResponseDto) {
    const toastr = this.toastrService.success(`Mint success`, 'Success', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.router.navigate(['/pages/dashboard']);
        this.spinner.hide();
      });
  }

  runError(exception: any) {
    const toastr = this.toastrService.error(exception.error.errors[0], 'Error', {
      progressBar: true
    });

    if (toastr)
      toastr.onHidden.subscribe(() => {
        this.spinner.hide();
      });
  }
}
