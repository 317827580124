<nav class="navbar navbar-expand-lg navbar-light pt-4 px-4 px-md-0">
  <div class="containerDashboard d-flex justify-content-between align-items-center">
    <button class="btn bg-white pl-0" (click)="toggleSidebar()">
      <img src="../../../assets/img-bft/menu.svg" alt="" />
    </button>

    <section class="d-flex align-items-center">
      <select class="mr-3 selector" #langSelect (change)="translate.use(langSelect.value)">
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">
          {{ lang }}
        </option>
      </select>

      <button class="btnX btn-secondX d-flex justify-content-center align-items-center ml-2" href="javascript:void(0)" (click)="logoff()">
        <img src="../../../assets/img-bft/log-out.svg" class="col-2 px-0 mr-2" alt="" />Log out
      </button>
    </section>
  </div>
</nav>