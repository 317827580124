import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { RegisterUserDto } from '../dtos/register-user-dto';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../utils/localstorage-util';
import { Router } from '@angular/router';
import { GetUserDto } from '../dtos/get-user.dto';

@Injectable()
export class UserService extends BaseService {
    constructor(
        private readonly httpClient: HttpClient,
        private router: Router
    ) {
        super();
    }

    public saveJwtLocalStorage(jwt: string) {
        this.LocalStorage.set(LocalStorageKeys.jwt, jwt);;
    }

    public getJwtLocalStorage(): string {
        return this.LocalStorage.get(LocalStorageKeys.jwt);;
    }

    public saveUserLocalStorage(userEmail: string) {
        this.LocalStorage.set(LocalStorageKeys.user, userEmail);;
    }

    public getUserLocalStorage(): string {
        return this.LocalStorage.get(LocalStorageKeys.user);
    }

    public removeUserLocalStorage() {
        this.LocalStorage.remove(LocalStorageKeys.user);
    }

    register(dto: RegisterUserDto): Observable<any> {
        const response = this.httpClient
            .post(`${this.url}/user`, dto, this.getHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError));
        return response;
    }

    authenticate(userEmail: string, userPassword: string): Observable<string> {
        const response = this.httpClient
            .post(`${this.url}/account`, { userEmail, userPassword }, this.getHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
        return response;
    }

    logout() {
        this.LocalStorage.remove(LocalStorageKeys.jwt);
        this.router.navigate(['/']);
    }

    getByEmail(userEmail: string): Observable<GetUserDto> {
        const response = this.httpClient
            .post(`${this.url}/account-info`, { userEmail }, this.getAuthenticationHeaderJson())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
        return response;
    }

    isLogged(): boolean {
        const token = this.getJwtLocalStorage();
        if (token)
            return true;
        else
            return false;
    }
}
