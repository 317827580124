import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { CustomFormsModule } from 'ngx-custom-validators';
import { NgxSpinnerModule } from "ngx-spinner";

import { TokenService } from '../services/token.service';
import { WalletService } from '../services/wallet.service';
import { SecurityService } from '../services/security.service';
import { UserService } from '../services/user.service';
import { ProductService } from '../services/product.service';
import { PaymentService } from '../services/payment.service';
import { LicenseService } from '../services/license.service';
import { SetupService } from '../services/setup.service';
import { DataService } from '../utils/data.service';

import { NavComponent } from '../layout/nav/nav.component';
import { NotFoundComponent } from '../layout/not-found/not-found.component';

import { PagesAppComponent } from './pages.app.component';
import { PagesRoutingModule } from './pages.route';
import { CreateTokenComponent } from './create-token/create-token.component';
import { CreateTokenDescriptionComponent } from './create-token-description/create-token-description.component';
import { CreateTokenInterfaceComponent } from './create-token-interface/create-token-interface.component';
import { CreateTokenResumeComponent } from './create-token-resume/create-token-resume.component';
import { CreateTokenRegisterComponent } from './create-token-register/create-token-register.component';
import { ChoosePlanComponent } from './choose-plan/choose-plan.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { CreateTokenDetailsComponent } from './create-token-details/create-token-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { MintToComponent } from './mint-to/mint-to.component';
import { BurnFromComponent } from './burn-from/burn-from.component';
import { NavLogComponent } from '../layout/nav-logged/nav-logged.component';
import { SidebarComponent } from '../layout/sidebar/sidebar.component';
import { DashboardTokenComponent } from './dashboard-token/dashboard-token.component';
import { DashboardPlanComponent } from './dashboard-plan/dashboard-plan.component';
import { DashboardAccountComponent } from './dashboard-account/dashboard-account.component';
import { EditPlanComponent } from './edit-plan/edit-plan.component';
import { SidebarService } from '../services/sidebar.service';

@NgModule({
    declarations: [
        PagesAppComponent,
        NavComponent,
        NotFoundComponent,
        CreateTokenComponent,
        CreateTokenDescriptionComponent,
        CreateTokenInterfaceComponent,
        CreateTokenResumeComponent,
        CreateTokenRegisterComponent,
        ChoosePlanComponent,
        DashboardComponent,
        DashboardTokenComponent,
        DashboardPlanComponent,
        DashboardAccountComponent,
        LoginComponent,
        CreateTokenDetailsComponent,
        MintToComponent,
        BurnFromComponent,
        EditPlanComponent,
        NavLogComponent,
        SidebarComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        PagesRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CustomFormsModule,
        TextMaskModule,
        NgxSpinnerModule,
        TranslateModule,
        NgSelectModule
    ],
    providers:[
        TokenService,
        WalletService,
        SecurityService,
        UserService,
        ProductService,
        PaymentService,
        SetupService,
        LicenseService,
        DataService,
        SidebarService
    ]
})
export class PagesModule { }
