<section class="bg-description">
  <div class="containerX p-3">
    <div class="text-create">
      <h1 class="
          text-center
          font-archivo font-size-44
          color-blue-third
          font-weight-semibold
        ">
        Create token / {{ 'PAGE.DESCRIPTION' | translate }}
      </h1>
      <p class="text-center mx-auto col-9">
        {{ 'PAGE.DESCRIPTION_MESSAGE' | translate }}
      </p>
    </div>

    <form class="card-shadow mx-auto w-100 col-sm-10 mb-sm-5" (ngSubmit)="create()" [formGroup]="form">
      <section class="form-group py-5">
        <div class="d-block d-sm-flex justify-content-center">
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="name">{{ 'PAGE.NAME' | translate }}</label>
            <input type="text" class="form-control" id="name" name="name" formControlName="name" [ngClass]="{
                'is-invalid':
                  form.controls.name.invalid && form.controls.name.dirty,
                'is-valid': form.controls.name.valid
              }" />
            <span class="text-danger" *ngIf="form.controls.name.invalid && form.controls.name.dirty">
              <p *ngIf="form.controls.name.errors.required">
                Name is required!
              </p>
            </span>
          </div>
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="symbol">{{ 'PAGE.SYMBOL' | translate }}</label>
            <input type="text" class="form-control" id="symbol" name="symbol" formControlName="symbol" maxlength="3" [ngClass]="{
                'is-invalid':
                  form.controls.symbol.invalid && form.controls.symbol.dirty,
                'is-valid': form.controls.symbol.valid
              }" />
            <span class="text-danger" *ngIf="form.controls.symbol.invalid && form.controls.symbol.dirty">
              <p *ngIf="form.controls.symbol.errors.required">
                Symbol is required!
              </p>
            </span>
          </div>
        </div>
        <div class="d-block d-sm-flex justify-content-center">
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="type">{{ 'PAGE.TYPE' | translate }}</label>
            <input type="text" class="form-control" id="type" name="type" placeholder="ERC20" readonly />
          </div>
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="decimalPlaces">{{ 'PAGE.DECIMALS' | translate }}</label>
            <select type="text" class="form-control" id="decimalPlaces" name="decimalPlaces" formControlName="decimalPlaces" [ngClass]="{
                'is-invalid':
                  form.controls.decimalPlaces.invalid &&
                  form.controls.decimalPlaces.dirty,
                'is-valid': form.controls.decimalPlaces.valid
              }">
              <option value="18">18</option>
              <option value="17">17</option>
              <option value="16">16</option>
              <option value="15">15</option>
              <option value="14">14</option>
              <option value="13">13</option>
              <option value="12">12</option>
              <option value="11">11</option>
              <option value="10">10</option>
              <option value="9">9</option>
              <option value="8">8</option>
              <option value="7">7</option>
              <option value="6">6</option>
              <option value="5">5</option>
              <option value="4">4</option>
              <option value="3">3</option>
              <option value="2">2</option>
              <option value="1">1</option>
              <option value="0">0</option>
            </select>
            <span class="text-danger" *ngIf="
                form.controls.decimalPlaces.invalid &&
                form.controls.decimalPlaces.dirty
              ">
              <p *ngIf="form.controls.decimalPlaces.errors.required">
                Decimal places is required!
              </p>
              <p *ngIf="form.controls.decimalPlaces.errors.number">
                Decimal places is invalid! Must be a number!
              </p>
            </span>
          </div>
        </div>
        <div class="d-block d-sm-flex justify-content-center">
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="totalSupply">{{ 'PAGE.SUPPLY' | translate }}</label>
            <input type="text" class="form-control" id="totalSupply" name="totalSupply" formControlName="totalSupply" [ngClass]="{
                'is-invalid':
                  form.controls.totalSupply.invalid &&
                  form.controls.totalSupply.dirty,
                'is-valid': form.controls.totalSupply.valid
              }" />
            <span class="text-danger" *ngIf="
                form.controls.totalSupply.invalid &&
                form.controls.totalSupply.dirty
              ">
              <p *ngIf="form.controls.totalSupply.errors.required">
                Total supply is required!
              </p>
              <p *ngIf="form.controls.totalSupply.errors.number">
                Total supply is invalid! Must be a number!
              </p>
            </span>
          </div>
          <div class="col-12 col-sm-6 py-2">
            <label class="font-archivo font-size-18 font-weight-medium m-0" for="type"> </label>
            <button class="btn btn-primaryX w-100" [disabled]="form.invalid">
              {{ 'PAGE.NEXT' | translate }}
            </button>
          </div>
        </div>
      </section>
    </form>
  </div>
</section>

<footer class="text-center py-4">
  <p class="m-0 color-blue-third font-italic font-weight-medium font-size-14">
    © BFT Solutions - Todos os direitos reservados
  </p>
</footer>