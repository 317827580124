import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'ngx-custom-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateTokenModel, TokenStatus } from 'src/app/models/create-token-model';
import { TokenService } from 'src/app/services/token.service';
import { ToastrService } from 'ngx-toastr';
import { GetTokenDto } from 'src/app/dtos/get-token.dto';

@Component({
  selector: 'app-create-token-description',
  templateUrl: './create-token-description.component.html',
  styleUrls: ['./create-token-description.component.css']
})
export class CreateTokenDescriptionComponent {

  form: FormGroup;
  model: CreateTokenModel;
  tokenCheckSymbol: GetTokenDto;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private tokenService: TokenService,
    private toastrService: ToastrService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      symbol: ['', Validators.required],
      type: ['ERC20'],
      decimalPlaces: ['', [Validators.required, CustomValidators.number]],
      totalSupply: ['', [Validators.required, CustomValidators.number]],
      status: [TokenStatus.awaitingPayment, Validators.required]
    });
  }

  create() {
    try {
      this.spinner.show();

      this.model = Object.assign({}, this.form.value);

      this.tokenService
        .getBySymbol(this.model.symbol)
        .subscribe((response: GetTokenDto) => {
          this.tokenCheckSymbol = response
        });

      if (this.tokenCheckSymbol !== undefined) {

        const toastr = this.toastrService.error('Error', 'Symbol already taken, try other!', {
          progressBar: true
        });

        if (toastr)
          toastr.onHidden.subscribe(() => {
            this.spinner.hide();
          });

      } else {

        this.tokenService.saveLocalStorage(this.model);
        this.spinner.hide();
        this.router.navigate(['/pages/create-token-inter']);
      }
    } catch (err) {
      console.error(err);

      const toastr = this.toastrService.error('Error', 'Symbol already taken, try other!', {
        progressBar: true
      });

      if (toastr)
        toastr.onHidden.subscribe(() => {
          this.spinner.hide();
        });
    }
  }
}
