<section class="bg-home">
  <div class="containerX p-3">
    <div>
      <h1 class="color-blue-third font-archivo font-size-44 font-weight-semibold">
        BFT Token Factory
      </h1>
      <p class="col-sm-12 mb-4 p-0">{{ 'PAGE.PARAGRAPH_TOKEN' | translate }}</p>
      <button class="btnX btn-primaryX" [routerLink]="['/pages/create-token-desc']">
        {{ 'PAGE.START' | translate }}
      </button>
    </div>

    <div class="col-11 mt-5">
      <img src="../../../assets/img/img-home.svg" alt="" />
    </div>
  </div>
</section>
<footer class="text-center py-4">
  <p class="m-0 color-blue-third  font-weight-medium font-size-14">
    © BFT Solutions - Todos os direitos reservados
  </p>
</footer>