import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { PaymentModel } from '../models/payment.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { RegisterPaymentDto } from '../dtos/register-payment.dto';
import { LocalStorageKeys } from '../utils/localstorage-util';

@Injectable()

export class PaymentService extends BaseService {
  constructor(
    private readonly httpClient: HttpClient
  ) {
    super();
  }

  public savePaymentLocalStorage(dto: RegisterPaymentDto) {
    this.LocalStorage.set(LocalStorageKeys.payment, dto);
  }

  public getPaymentLocalStorage(): RegisterPaymentDto {
    return this.LocalStorage.get(LocalStorageKeys.payment);
  }

  public removePaymentLocalStorage() {
    this.LocalStorage.remove(LocalStorageKeys.payment);
  }

  getByUser(userId: string): Observable<PaymentModel[]> {
    return this.httpClient
      .get(`${this.url}/payment/user/${userId}`, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError));
  }

  registerPayment(dto: RegisterPaymentDto): Observable<any> {
    return this.httpClient
      .post(`${this.url}/payment/create`, dto, this.getAuthenticationHeaderJson())
      .pipe(
        map(this.extractData),
        catchError(this.serviceError));
  }
}
