import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../../models/product';
import { TokenService } from 'src/app/services/token.service';
import { DataService } from 'src/app/utils/data.service';
import { GetTokenDto } from 'src/app/dtos/get-token.dto';
import { PaymentService } from 'src/app/services/payment.service';
import { RegisterPaymentDto } from 'src/app/dtos/register-payment.dto';
import { SetupService } from 'src/app/services/setup.service';
import { LicenseService } from 'src/app/services/license.service';
import { RegisterSetupDto } from 'src/app/dtos/register-setup.dto';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.css']
})
export class ChoosePlanComponent implements OnInit {
  userId: string;
  tokenId: string;
  productsData: Product[];
  defaultProducts: Product[];
  form: FormGroup;
  totalSetupPrice = 0;
  totalMonthlyPrice = 0;
  payment = new RegisterPaymentDto('', '', 0, ' ', []);

  get productsFormArray() {
    return this.form.controls.products as FormArray;
  }
  constructor(
    private userService: UserService,
    private tokenService: TokenService,
    private productService: ProductService,
    private paymentService: PaymentService,
    private setupService: SetupService,
    private licenseService: LicenseService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private dataService: DataService
  ) {

    this.form = this.formBuilder.group({
      products: new FormArray([])
    });

  }

  ngOnInit(): void {
    this.spinner.show();
    this.userId = this.userService.getUserLocalStorage();
    this.dataService.currentTokenId.subscribe(tokenId => this.tokenId = tokenId);
    if (this.tokenId == undefined) {
      this.tokenService
        .getLastByUser(this.userId)
        .subscribe((response: GetTokenDto) => {
          this.tokenId = response._id
        });
    }
    this.listProducts();
    this.spinner.hide();
  }

  private addCheckboxes() {
    this.productsData.forEach(() => this.productsFormArray.push(new FormControl(false)));
  }

  async listProducts() {
    const resultProducts = this.productService.getProduct();
    resultProducts.subscribe((response: Product[]) => {
      this.productsData = response;
      this.addCheckboxes();
    });
  }

  FieldsChange(values: any, priceSetup: number, priceMonthly: number) {
    if (values.currentTarget.checked) {
      this.totalSetupPrice = +this.totalSetupPrice + +priceSetup;
      this.totalMonthlyPrice = +this.totalMonthlyPrice + +priceMonthly;
    } else {
      this.totalSetupPrice = this.totalSetupPrice - priceSetup;
      this.totalMonthlyPrice = this.totalMonthlyPrice - priceMonthly;
    }
  }

  submit() {
    const selectedProductsIds = this.form.value.products
      .map((checked, i) => checked ? this.productsData[i].id : null)
      .filter(v => v !== null);
    return selectedProductsIds;
  }

  getDate() {
    let date_ob = new Date();
    let day = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    return year + "-" + month + "-" + day;
  }

  async registerPlan() {
    this.spinner.show();
    const selectedProductsIds = this.submit();
    this.selectTokenId(this.tokenId);
    const date = this.getDate();
    const totalPaymentPrice = this.totalSetupPrice + this.totalMonthlyPrice;
    this.paymentService.savePaymentLocalStorage(new RegisterPaymentDto(this.userId, this.tokenId, totalPaymentPrice, date, selectedProductsIds));
    this.router.navigate(['pages/payment', this.tokenId, this.userId, "CreateToken", totalPaymentPrice]);
    this.spinner.hide();
  }

  selectTokenId(newId: string){
    this.dataService.changeTokenId(newId)
  }

}
